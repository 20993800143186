import { Box, Image, Skeleton } from "@chakra-ui/react";
import { useRouter } from "next/router";
import SwiperCore, {
  A11y,
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

export default function Slider({ sliderImages }) {
  const router = useRouter();
  const navigateToProducts = () => router.push("/shop");

  return (
    <Box maxWidth="100%" width="100%">
      <Swiper
        breakpoints={{
          600: {
            height: "100%",
          },
        }}
        effect="fade"
        slidesPerView={1}
        spaceBetween={50}
        autoplay={{ delay: 6000 }}
        navigation={{ nextEl: "custom-next-btn" }}
        pagination={{
          clickable: true,
          bulletActiveClass: "bullet-class-active",
        }}
      >
        {sliderImages &&
          sliderImages.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <Image
                  w="100%"
                  lazy="true"
                  height={{
                    lg: "90vh"
                  }}
                  alt={item.caption}
                  objectFit = {{ base: "contain", xl: "cover" }}
                  sizes={item.sizes}
                  src={item.sourceUrl}
                  srcSet={item.srcSet}
                  className="image-opacity"
                  fallback={
                    <Skeleton
                      startColor="golden"
                      height={{
                        base: "91vh",
                        sm: "92vh",
                        md: "93vh",
                        lg: "85vh",
                        xl: "85vh",
                        "2xl": "90vh",
                      }}
                    />
                  }
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </Box>
  );
}
