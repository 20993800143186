import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Fab } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import find from "lodash/find";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import toaster from "toasted-notes";

import { HOMEPAGE_VIDEOS, HOMEPAGE_IMAGES } from "../src/constants";
import { Button, Error, FreeDeliveryOffer } from "../src/components/common";
import ErrorToaster from "../src/components/common/error/errorToaster";
import Slider from "../src/components/home/slider";
import ProductVideoList from "../src/components/products/productVideoList";
import RelatedImageList from "../src/components/products/relatedImageList";

const HorizontalProductList = dynamic(() =>
  import("../src/components/home/horizontalProductList")
);

const VideoModal = dynamic(() => import("../src/components/home/videoModal"), {
  ssr: false,
});

import { getImageData } from "../src/services/products";
import { FEATURE_PRODUCTS_SKU, SLIDER_IMAGE_IDS } from "../src/constants";
import { fetchProductCategories, fetchProducts } from "../src/redux/actions";
import { wrapper } from "../src/redux/store";
import { fetchSliderImages } from "../src/services/mediaService";
import { fetchYoutubeVideosByIds } from "../src/services/youtubeVideos";

export default function Home({ sliderImages, errorMessage }) {
  if (errorMessage) {
    return <Error errorMessage={errorMessage} />;
  }

  const router = useRouter();

  const [showProductList, setShowProductList] = useState(false);
  const [productVideos, setProductVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [featuredImages, setFeaturedImages] = useState([]);

  const { cart, products } = useSelector((state) => state);
  const cartProductIds = cart.map((product) => product.id);

  const featuredProducts = [];
  FEATURE_PRODUCTS_SKU.forEach((featureSku) => {
    const featuredProduct = find(products, { sku: featureSku });
    if (featuredProduct) {
      featuredProducts.push(featuredProduct);
    }
  });

  useEffect(() => {
    getYoutubeVideos();
    getFeaturedImages();
  }, []);

  const getYoutubeVideos = async () => {
    try {
      const response = await fetchYoutubeVideosByIds(HOMEPAGE_VIDEOS);
      if (response.success && response.data) {
        setProductVideos(response.data);
      } else {
        toaster.notify(<ErrorToaster errorMessage={response.message} />, {
          duration: 3000,
          position: "top",
        });
      }
    } catch (err) {
      toaster.notify(<ErrorToaster errorMessage={err.message} />, {
        duration: 3000,
        position: "top",
      });
    }
  };

  const getFeaturedImages = async () => {
    const featuredImages = []
    for (const featuredImageId of HOMEPAGE_IMAGES) {
      const featuredImageData = await getImageData(featuredImageId);
      featuredImages.push(featuredImageData)
    }
    setFeaturedImages([...featuredImages]);
  };

  const handleOpenVideoModal = (videoId) => {
    setActiveVideo(videoId);
    setIsVideoModalOpen(true);
  };
  const handleCloseVideoModal = () => {
    setActiveVideo(null);
    setIsVideoModalOpen(false);
  };

  return (
    <>
      <NextSeo
        openGraph={{
          type: "website",
          url: "https://www.drvasishths.com/",
          title: "Dr.Vasishth's AyuRemedies",
          description:
            "Dr.Vasishth’s is playing a unique role of improving lives by offering effective and disease-modifying ayurveda healthcare products. It is essentially owing to the innovation of a broad range of products customized to strike at the very root of the disease processes.",
          images: [
            {
              url: "https://www.drvasishths.in/wp-content/uploads/2020/10/onlylogo-e1603604348459.png",
              width: 250,
              height: 250,
              alt: "Company Logo",
            },
          ],
        }}
      />
      <Flex w="100%" height="fit-content" direction="column">
        <Slider sliderImages={sliderImages} />
        <Center>
          <Box
            mt={{ base: "25px", md: "50px", xl: "75px" }}
            width={{ base: "90%", "2xl": "1350px" }}
          >
            <Text
              fontSize={{ base: "24px", md: "30px" }}
              variant="headingHomePage"
              color="maroon"
            >
              Dr.Vasishth’s is playing a unique role of offering real ayurveda
              remedies for addressing the health challenges facing the 21st
              century people across the globe.
            </Text>
            <Text mt="10px" variant="descriptionHomePage" color="titleColor">
              Dr.Vasishth’s is playing a unique role of improving lives by
              offering effective and disease-modifying ayurveda healthcare
              products. It is essentially owing to the innovation of a broad
              range of products customized to strike at the very root of the
              disease processes.
            </Text>
            <Button
              mt="20px"
              py="10px"
              px="20.5px"
              backgroundColor="golden"
              title="Know more about us"
              handleClick={() => router.push("/about-us")}
            />
            <Flex
              mt="75px"
              flexDirection={{ base: "column", lg: "row" }}
              justifyContent={{ base: "flex-start", lg: "space-between" }}
            >
              <Box>
                <Flex
                  flexDirection={{ base: "column", sm: "row" }}
                  alignItems={{ base: "flex-start", sm: "flex-end" }}
                  justifyContent={{ base: "flex-start" }}
                >
                  <Text
                    lineHeight="1"
                    color="titleColor"
                    variant="headingHomePage"
                  >
                    Explore Products
                  </Text>
                  <FreeDeliveryOffer />
                </Flex>
                <Text
                  mt="10px"
                  color="titleColor"
                  variant="descriptionHomePage"
                >
                  Explore our wide range of reliable products and get it
                  delivered at your doorsteps.
                </Text>
              </Box>
              <Box>
                <Button
                  mt={{ base: "20px", lg: "0px" }}
                  px="20.5px"
                  py="10px"
                  backgroundColor="golden"
                  title="View all products"
                  handleClick={() => router.push("/shop")}
                />
              </Box>
            </Flex>
            {products && products.length > 0 && (
              <InView>
                {({ inView, ref, entry }) => {
                  if (inView) setShowProductList(true);
                  return (
                    <div ref={ref}>
                      {showProductList && (
                        <HorizontalProductList
                          products={featuredProducts}
                          cartProductIds={cartProductIds}
                        />
                      )}
                    </div>
                  );
                }}
              </InView>
            )}
          </Box>
        </Center>
        <Center>
          {productVideos && productVideos.length > 0 && (
            <Box mt="50px" width={{ base: "90%", "2xl": "1350px" }}>
              <Flex
                flexDirection={{ base: "column", lg: "row" }}
                justifyContent={{ base: "flex-start", lg: "space-between" }}
              >
                <Box>
                  <Text
                    lineHeight="1"
                    color="titleColor"
                    variant="headingHomePage"
                  >
                    Watch Our Youtube Videos
                  </Text>
                </Box>
              </Flex>
              <ProductVideoList
                productVideos={productVideos}
                openVideoModal={handleOpenVideoModal}
              />
            </Box>
          )}
        </Center>
        <Center>
          {featuredImages && featuredImages.length > 0 && (
            <Box mt="50px" width={{ base: "90%", "2xl": "1350px" }}>
              <Flex
                flexDirection={{ base: "column", lg: "row" }}
                justifyContent={{ base: "flex-start", lg: "space-between" }}
              >
                <Box>
                  <Text
                    lineHeight="1"
                    color="titleColor"
                    variant="headingHomePage"
                  >
                    Recent Events
                  </Text>
                </Box>
              </Flex>
              <RelatedImageList relatedImages={featuredImages} />
            </Box>
          )}
        </Center>
        <VideoModal
          videoId={activeVideo}
          activeVideo={activeVideo}
          isVideoModalOpen={isVideoModalOpen}
          closeVideoModal={handleCloseVideoModal}
        />
        <Fab
          _hover={{
            cursor: "pointer",
            backgroundColor: "golden",
          }}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
          }}
          sizeMedium
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <ExpandLess />
        </Fab>
      </Flex>
    </>
  );
}

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  try {
    await store.dispatch(fetchProducts());
    await store.dispatch(fetchProductCategories());
    const sliderImages = await fetchSliderImages(SLIDER_IMAGE_IDS.join(","));

    return {
      props: {
        sliderImages: sliderImages.data || null,
      },
    };
  } catch (err) {
    return {
      props: {
        errorMessage: err.message,
      },
    };
  }
});
