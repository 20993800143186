import { AspectRatio, Box, Flex, Img, Skeleton, Text } from "@chakra-ui/react";
import parseDuration from "parse-duration";
import React from "react";

import PlayButton from "../../assets/svg/play_button";

const Index = ({ productVideo, openVideoModal }) => {
  const title = productVideo.snippet.title;
  const publishedAt = productVideo.snippet.publishedAt;

  const duration = Math.floor(parseDuration(productVideo.contentDetails.duration, 'second'));
  const minutes = Math.floor(duration / 60) === 0 ? 1 : Math.floor(duration / 60);
  const seconds = Math.floor((duration - Math.floor(duration / 60)*60) / 60);
  
  const jsDate = new Date(publishedAt);
  const month = jsDate.toLocaleString("default", { month: "long" });

  return (
    <>
      <Flex
        mb="30px"
        shadow="lg"
        flexWrap="wrap"
        maxWidth="400px"
        borderWidth="0px"
        overflow="hidden"
        borderRadius="5px"
        alignItems="center"
        height="fit-content"
        justifyContent="center"
        onClick={() => openVideoModal(productVideo.id)}
        width={{
          base: "90%",
          md: "340px",
          lg: "400px",
          xl: "340px",
          "2xl": "400px",
        }}
      >
        <Box
          position="relative"
          width="100%"
          height="100%"
          maxHeight="250px"
          overflow="hidden"
          _hover={{ cursor: "pointer" }}
        >
          <AspectRatio mb="3%" width="100%" ratio={16 / 9}>
            <Img
              lazy="true"
              width="100%"
              alt="image-alt"
              overflow="hidden"
              objectFit="cover"
              _hover={{ cursor: "pointer" }}
              fallback={<Skeleton w="100%" />}
              src={
                productVideo?.snippet?.thumbnails?.standard
                  ? productVideo.snippet.thumbnails.standard.url
                  : productVideo?.snippet?.thumbnails?.medium
                  ? productVideo?.snippet?.thumbnails?.medium?.url
                  : productVideo?.snippet?.thumbnails?.default?.url
              }
            />
          </AspectRatio>
          <Box top="40%" left="45%" position="absolute">
            <PlayButton height={40} height={40} />
          </Box>
          <Box top="82%" left="84%" position="absolute">
            <Text
              py="0.5px"
              px="5px"
              borderRadius={'20px'}
              color={"white"}
              bg={"black"}
              opacity="0.75"
              fontSize="small"
              fontWeight={"bold"}
            >
              {`${minutes}:${('0'+seconds).slice(-2)}`}
            </Text>
          </Box>
        </Box>
        <Flex width="100%" flexDirection="column" padding="10px">
          <Text
            noOfLines={3}
            color={"maroon"}
            variant="productCardTitle"
            _hover={{ cursor: "pointer" }}
          >
            {title}
          </Text>
          <Text
            mt={3}
            flexWrap="wrap"
            fontWeight="400"
            fontSize="12px"
            fontFamily="Lato"
            color="titleColor"
          >
            {`${month} ${jsDate.getDate()}, ${jsDate.getFullYear()}`}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default Index;
