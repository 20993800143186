import { Box, Flex, Img, Skeleton } from "@chakra-ui/react";
import React from "react";

const Index = ({ relatedImage }) => {
  return (
    <>
      <Flex
        mb="30px"
        shadow="lg"
        flexWrap="wrap"
        maxWidth="400px"
        borderWidth="0px"
        overflow="hidden"
        borderRadius="5px"
        alignItems="center"
        height="fit-content"
        justifyContent="center"
        width={{
          base: "90%",
          md: "340px",
          lg: "400px",
          xl: "340px",
          "2xl": "400px",
        }}
      >
        <Box position="relative" width="100%" height="100%" overflow="hidden">
          <Img
            lazy="true"
            width="100%"
            alt="image-alt"
            overflow="hidden"
            objectFit="contain"
            fallback={<Skeleton w="100%" />}
            src={relatedImage.sourceUrl}
          />
        </Box>
      </Flex>
    </>
  );
};

export default Index;
