import { Box, Center } from "@chakra-ui/react";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import RelatedImageCard from "./relatedImageCard";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Index({ relatedImages }) {
  return (
    <Box width="100%" mt="43px" justifyContent="center">
      <Swiper
        style={{ width: "100%" }}
        breakpoints={{
          // when window width is >= 640px
          2000: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 90,
          },
          1355: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
        }}
        spaceBetween={80}
        navigation
      >
        {relatedImages.map((relatedImage, index) => (
          <SwiperSlide key={index} style={{ width: "100%" }}>
            <Center width="100%">
              <RelatedImageCard
                relatedImage={relatedImage}
              />
            </Center>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
