const queryString = require("query-string");

export const fetchYoutubeVideos = async () => {
  try {
    const res = await fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=id,snippet&key=${process.env.NEXT_PUBLIC_YOUTUBE_API_DATA_KEY}&playlistId=${process.env.NEXT_PUBLIC_YOUTUBE_PLAYLIST_ID}&order=date&maxResults=10`
    );
    const response = await res.json();
    return { success: true, data: response.items };
  } catch (err) {
    return { success: false, message: err.message };
  }
};


export const fetchYoutubeVideosByIds = async (videoIds) => {
  const paramsString = queryString.stringify({
    part: 'id,snippet,contentDetails',
    key: process.env.NEXT_PUBLIC_YOUTUBE_API_DATA_KEY,
    id : videoIds.join()
  });

  try {
    const res = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?${paramsString}`
    );
    const response = await res.json();
    return { success: true, data: response.items };
  } catch (err) {
    return { success: false, message: err.message };
  }
};