import { Text } from "@chakra-ui/react";

export default function index() {
  return (
    <Text
      className="blink-me"
      variant="priceText"
      borderColor="maroon"
      mt={{ base: "5px", sm: "0px" }}
      ml={{ base: "0px", sm: "5px" }}
      fontSize={{ base: "12", lg: "13", xl: "14" }}
    >
      (Free Delivery for Orders over ₹1000)
    </Text>
  );
}
